<template>
   <cui-acl :roles="['underDevelopment']" redirect>
    <div>
      <h4>Instillinger Tabeller</h4>
    </div>
    <div class="mt-3">
      <div class="card">
      <div class="card-body">
        <h4>
          <span>Instillinger Tabeller</span>
          <a-button style="float: right;" @click="tables.push({ newTable: true , title: 'New Table', dataStructure: [], description: '' })">
            New Table
          </a-button>
        </h4>
        <a-collapse accordion>
          <a-collapse-panel :key="i" :header="table.title" v-for="(table, i) in tables">
            <a @click="copyBtn(table.id)" class="link">Table API <i class="fa fa-copy"></i></a>
            <a-form @submit.prevent="editTableSettings(i)">
              <div class="row">
                <div class="col-md-3">
                  <a-form-item label="Title">
                    <a-input placeholder="title" v-model="table.title"/>
                  </a-form-item>
                </div>
                <div class="col-md-9">
                  <a-form-item label="Description">
                    <a-input placeholder="Description" v-model="table.description"/>
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item label="Access">
                    <a-select
                      :filter-option="filterOption"
                      option-filter-prop="children"
                      v-model="table.access"
                      mode="multiple">
                      <a-select-option v-for="employee in employees" :key="employee.value" :value="employee.value">
                        {{ employee.label }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
              </div>
              <draggable v-model="table.dataStructure" group="people" @start="drag=true" @end="drag=false">
                <div class="card" v-for="(attribute, j) in table.dataStructure" :key="j">
                  <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <a-form-item label="Title">
                        <a-input placeholder="title" v-model="attribute.title"/>
                      </a-form-item>
                    </div>
                    <div class="col-md-3">
                      <a-form-item label="Type">
                        <a-select :options="dataTypes" v-model="attribute.type"/>
                      </a-form-item>
                    </div>
                    <!-- <div class="col-md-3">
                      <a-form-item label="Required">
                        <a-checkbox v-model="attribute.required">
                          Required
                        </a-checkbox>
                      </a-form-item>
                    </div> -->
                    <!-- input field to insert regex expression to validate field-->
                    <div class="col-md-3" v-if="attribute.type === 'string'">
                      <a-form-item label="Regex">
                        <a-input placeholder="regex" v-model="attribute.regex"/>
                      </a-form-item>
                    </div>
                    <div class="col-md-3" v-if="attribute.type === 'select'">
                      <a-form-item label="-">
                        <a-button @click.prevent="newOption(i,j)">New Option</a-button>
                      </a-form-item>
                    </div>
                    <div v-if="attribute.type === 'select'">
                      <div class="row" v-for="(option, k) in attribute.options" :key="k">
                        <div class="col-md-4">
                          <a-form-item label="Label">
                            <a-input placeholder="Label" v-model="option.text"/>
                          </a-form-item>
                        </div>
                        <div class="col-md-4">
                          <a-form-item label="Value">
                            <a-input placeholder="Value" v-model="option.value"/>
                          </a-form-item>
                        </div>
                        <div class="col-md-4">
                          <a-form-item lable="Delete option">
                            <a-button><i class="fa fa-trash" @confirm="table.dataStructure[j].options.splice(k, 1)"></i></a-button>
                          </a-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <a-form-item label="Slett">
                        <a-popconfirm
                          title="Are you sure delete this attribute?"
                          ok-text="Yes"
                          cancel-text="No"
                          @confirm="table.dataStructure.splice(j, 1)"
                        >
                          <a-button><i class="fa fa-trash"></i></a-button>
                        </a-popconfirm>
                      </a-form-item>
                    </div>
                  </div>
                </div>
                </div>
              </draggable>
              <a-button @click.prevent="table.dataStructure.push({})">New Attribute</a-button>

              <a-button html-type="submit" type="primary" class="btn btn-light px-5">Lagre</a-button>
            </a-form>
          </a-collapse-panel>
        </a-collapse>
      </div>
      </div>
    </div>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { openNotification } from '@/services/powerAutomate'
import draggable from 'vuedraggable'

import { firestore } from '@/services/firebase'

const dataTypes = [
  {
    value: 'string',
    label: 'String',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'boolean',
    label: 'Boolean',
  },
  {
    value: 'date',
    label: 'Date',
  },
  {
    value: 'employee',
    label: 'Employee',
  },
  {
    value: 'select',
    label: 'Select',
  },
]

export default {
  components: {
    cuiAcl,
    draggable,
  },
  data() {
    return {
      tables: [],
      dataTypes,
      employees: [],
      apikey: 'wwqUGqJc7XzBvK258ZBF5OTVyFo',
    }
  },
  methods: {
    copyBtn(tableId) {
      const el = document.createElement('textarea')
      el.value = `https://europe-west1-skyportal2.cloudfunctions.net/getTables?api=${this.apikey}&table=${tableId}`
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      openNotification('success', 'API copied to clipboard')
    },
    newOption(i, j) {
      const newObject = {
        label: '',
        value: '',
      }
      if (!this.tables[i].dataStructure[j].options) {
        this.tables[i].dataStructure[j].options = [newObject]
      } else {
        this.tables[i].dataStructure[j].options.push(newObject)
      }
      this.$forceUpdate()
    },
    editTableSettings(i) {
      const newData = this.tables[i]
      console.log(newData)
      if (!newData.newTable) {
        delete newData.newTable
        firestore.collection('supportTables').doc(this.tables[i].id).update(newData)
          .then(() => {
            openNotification('success', 'Table settings updated')
          })
      } else {
        console.log(newData)
        delete newData.newTable
        this.tables[i].id = firestore.collection('supportTables').add(newData)
          .then(() => {
            openNotification('success', 'Table settings updated')
          }).id
      }
      newData.access.forEach(employee => {
        this.checkEmployeeRole(employee)
      })
      console.log(this.tables)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    checkEmployeeRole(id) {
      firestore.collection('users').doc(id).get()
        .then(doc => {
          if (doc.data().roles.includes('supporttables')) {
            return true
          } else {
            // add supporttables as access to user
            firestore.collection('users').doc(id).update({
              roles: [...doc.data().roles, 'supporttables'],
            })
            return true
          }
        })
    },
  },
  mounted() {
    let ref = firestore.collection('supportTables')
    if (['underDevelopment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      firestore.collection('users').get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            this.employees.push({
              value: doc.id,
              label: doc.data().fullName,
            })
          })
        })
    } else {
      ref = ref.where('access', 'array-contains', this.$store.state.user.id)
    }
    ref.onSnapshot(snapshot => {
      this.tables = []
      snapshot.forEach(doc => {
        this.tables.push({
          id: doc.id,
          title: doc.data().title,
          subtext: doc.data().description,
          description: doc.data().description,
          dataStructure: doc.data().dataStructure,
          newTable: false,
          access: doc.data().access,
        })
      })
    })
  },
}
</script>

<style>

</style>
